@import "global";
#topnav {
  min-height:75px;
  .logo-dark{
    width: 125px;
    @include media-breakpoint-up(md) {
      width: 135px;
    }

    @include media-breakpoint-up(lg) {

      width: 100%;
    }

    @include media-breakpoint-up(xl) {
    
    }
  }
  #navigation {
    background-color: #ffffff;
  }
  background: #FFFFFF !important;
  .buy-button {
    max-height: 74px;
    padding: 0px 0;
    .has-submenu {
      .menu-arrow {
        top: 30px;
        right: 0px;
        @include media-breakpoint-up(md) {
          top: 30px;
        }
  
        @include media-breakpoint-up(lg) {
  
          top: 30px;
        }
  
        @include media-breakpoint-up(xl) {
          top: 30px;
        }
      }
      a {
        padding: 15px 15px !important;
       

      }
    }
    .navigation-menu{
      display:flex;
      @include media-breakpoint-up(md) {
        display:flex;
      }

      @include media-breakpoint-up(lg) {

        display:block;
      }

      @include media-breakpoint-up(xl) {
       display:block;
      }
    }
    .navigation-menu > li .submenu li a {
      line-height: 0;

    }
    .navigation-menu > li .submenu{
    border-radius:0;
    }
  .navigation-menu > li > a{
padding-left:0px;
padding-right:0px;
    }
  }
  .group-person {
    display: flex;

    .group-avatar {
      background-image: url("../images/avatar.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      height: 40px;
      width: 40px;

      margin-right: 10px;
    }
    .group-name {
      align-self: center;
    }
  }

  .navigation-menu {
    justify-content: flex-start;
  }
  .logo {
    line-height: none;
    img{
      height:35px;
    }
  }
  &.scroll-active {
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
    .navigation-menu > li > a {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #333333;
      text-transform: none;
      padding-top: 30px;
    }
    .navigation-menu > li > .menu-arrow {
      border-color: #333333;
      top:32px;
    }
    .navigation-menu > li:hover > a,
    .navigation-menu > li.active > a {
      color: #f47421;
    }
    .navigation-menu > li:hover > .menu-arrow,
    .navigation-menu > li.active > .menu-arrow {
      border-color: #f47421;
    }
  }
  &.scroll {
    background: #0e0e0e;
    .navigation-menu > li > a {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #333333;
      opacity: 0.7;
      text-transform: none;
      padding-top: 30px;
    }
    .navigation-menu > li > .menu-arrow {
      border-color: #333333;
    }
  }
  .navigation-menu > li .submenu{
    border-radius:0;
    }
}
#topnav .navigation-menu > li > a {
  letter-spacing: 0px;
}