.login-page {
  max-width: 620px;
  position: relative;
  top: -185px;
  padding: 70px 50px !important;
  box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
  h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 33px;

    color: #333333;
  }
  .text-small-log {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #828282;
  }
  .f-14 {
    font-size: 14px !important;
  }
  .btn-login-facebook,
  .btn-login-google {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #303030;
    img {
      margin-right: 5px;
    }
    &:hover {
      color: #fff;
    }
  }
  .width_common {
    width: 100%;
    float: left;
  }
  .or_text {
    text-align: center;
    position: relative;
    &.or_text:before {
      width: 100%;
      height: 1px;
      content: "";
      background: #e0e0e0;
      position: absolute;
      top: 50%;
      margin-top: -0.5px;
      left: 0;
    }
    span {
      display: inline-block;
      padding: 0 10px;
      background: #ffffff;

      position: relative;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;

      color: #333333;
    }
  }
  .input-group-text {
    background-color: #fff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
  }
  .input-group {
    input {
      border: 1px solid #e9e9e9;
      border-left: none;
      background-color: #fff !important;
    }
  }
  .custom-control-label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #333333;
  }
  .forgot-pass {
    a {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;

      color: #2f80ed !important;
    }
  }
  .group-policy {
    a {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #828282;
    }
  }
  .agree-policy {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    b {
      color: #2f80ed !important;
      font-weight: normal;
    }
  }
}
.group-form {
  height: 100vh;
}
.group-account {
  h4.header-job-description {
    margin-top: 0;
  }
}
.view-pass {
  position: absolute;
  right: 15px;
  top: 12px;
  z-index: 9;
}

.blogs .social-icon li a {
  color: #3c4858;
}
.notification-icon {
  i {
    &.fa-bell-on {
      margin-top: 12px;
    }
    font-size: 20px;
  }
  .submenu {
    width: 300px;
    li {
      a {
        overflow-wrap: break-word !important;
        line-height: 120% !important;
        white-space: unset !important;
        font-size: 14px !important;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        b {
          color: #f47421;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
        }
      }
    }
  }
}
.group-company-infomation {
  padding-bottom: 50px;
}
.group-total-start {
  .group-propress {
    display: grid;
    grid-template-columns: 32px 1fr 29px;
    .progress {
      margin-right: 5px;
    }
  }
}
