.company-page {
  padding: 15px 0px 0px 0px;
  .company-cover {
    min-height: 300px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 -15px;
    @include media-breakpoint-up(md) {
      margin: 0 -15px;
    }

    @include media-breakpoint-up(lg) {
      margin: 0;
    }

    @include media-breakpoint-up(xl) {
      margin: 0;
    }
  }
}
.group-company-infomation {
  .company-infomation {
    margin-top: -30px;
    margin-bottom: 30px;
  }
  .company-infomation-detail {
    background-color: #fff;
    border-radius: 8px !important;
    min-height: calc(100vh - 448px);
    .group-info {
      border-right: 0px solid #e9e9e9;
      display: grid;
      grid-template-columns: 110px 1fr;

      @include media-breakpoint-up(md) {
        border-right: 0px solid #e9e9e9;
      }

      @include media-breakpoint-up(lg) {
        border-right: 1px solid #e9e9e9;
      }

      @include media-breakpoint-up(xl) {
        border-right: 1px solid #e9e9e9;
      }

      .image-company {
      }
      h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        color: #333333;
        margin-bottom: 0px;
      }
      .job-detail-location {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        max-width: 380px;
        .job-details-desc-item {
          div {
            display: block;
            width: 100%;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 5px;
            color: #828282;
          
          }
          p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #000000;
            font-weight: bold;
          }
        }
      }
      .image-company {
        max-width: 100px;
        margin-right: 10px;
        img {
          width: 100%;
          width: 100px;
          height: 100px;
          object-fit: contain;
          border-radius: 4px;
        }
      }
    }
    .group-apply {
      display: grid;
      grid-template-columns: 100px 145px;
      .group-follow {
        padding-top: 10px;
        cursor: pointer;
        &:hover{
          color: #f47421;
        }
      }
      .btn-apply-cv {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;

        color: #ffffff;
      }
    }
    .job-list-box {
      border: none !important;
      border-bottom: 0px solid #e9e9e9 !important;
      .job-list-desc h6 a {
        
        font-size: 16px;
       
    }
    }
  }
  .nav-tabs {
    .nav-item.show .nav-link,
    .nav-link.active {
      color: #f47421;
      background-color: #fff;
      background-color: transparent;
      border-color: transparent transparent #30b188;
      border-bottom: 3px solid !important;
    }
    .nav-link:focus,
    .nav-link:hover {
      border-color: transparent;
    }
    a {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #828282;
    }
  }
  .group-review {
    .job-list-box {
      h6 {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;

        color: #333333;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;

        color: #333333!important;
      }
    }
  }
}
.group-total-review {
  .group-total-point {
    display: flex;
    justify-content: center;
    .text-point {
      h2 {
        font-style: normal;
        font-weight: 900;
        font-size: 42px;
        line-height: 49px;
        margin-bottom: 0px;
        color: #333333;
      }
      #frmCvoPromptStars {
        margin-top: 1px;
      }
    }
  }
}
.shadow {
  box-shadow: 0px 4px 15px rgba(0, 35, 61, 0.1) !important;
}
.group-listing {
  .group-detail-description {
    padding: 15px;
    .job-detail-location {
      grid-template-columns: 1fr 10px 1fr;
      border-top: none !important;
      @include media-breakpoint-up(md) {
        grid-template-columns: 1fr 10px 1fr;
      }

      @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr 10px 1fr;
      }

      @include media-breakpoint-up(xl) {
        grid-template-columns: 1fr 10px 1fr;
      }
      .job-details-desc-item {
        i.fa-star {
          color: #ffab36;

          font-weight: bold;
        }
        span {
          color: #ffab36;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }

  .how-it-work-box {
    border: none;
    border-radius: 8px;
    min-height: 310px;
    min-width: 255px;
    .card-img-top {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      height: 130px;
      display: flex;
      justify-content: flex-start;
      img {
        width: 50px;
    height: 50px;
    object-fit: contain;
        border: 2px solid rgba(255, 255, 255, 0.5);
        border-radius: 3px;
        align-self: flex-end;
        margin: 0 0 10px 15px;
      }
    }
    .card-title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #333333;
    }
    .card-text {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #828282;
      min-height: 16px;
    }
  }
}
.group-list-menu {
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
 display: grid;
 grid-template-columns: 1fr 1fr 1fr 1fr;
  a {
    text-decoration: none;
    color: #000;
    margin: 0 10px;
    display: block;
    padding: 0 5px;
  }
}.progress{
  height: 10px;
  margin: 5px 5px;
}
.progress-bar {
  background-color: #21BAF4;
}
$borderWidth: 7px;
$animationTime: .5s;
$border-color-default: #efefef;
$border-color-fill: #21BAF4;
$size: 70px;
$howManySteps: 100; //this needs to be even. 

.progress-cir {
  width: $size;
  height: $size;
  line-height: $size;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: $borderWidth solid $border-color-default;
    position: absolute;
    top: 0;
    left: 0;
  }
  > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .progress-cir-left {
    left: 0;
  }
  .progress-cir-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: $borderWidth;
    border-style: solid;
    position: absolute;
    top: 0;
    border-color: $border-color-fill;
  }
  .progress-cir-left .progress-cir-bar {
    left: 100%;
    border-top-right-radius: ($size/2);;
    border-bottom-right-radius: ($size/2);;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
    //animation: loading-2 1.5s linear forwards 1.8s;
  }
  .progress-cir-right {
    right: 0;
    .progress-cir-bar {
      left: -100%;
      border-top-left-radius: ($size/2);;
      border-bottom-left-radius: ($size/2);;
      border-right: 0;
      -webkit-transform-origin: center right;
      transform-origin: center right;
      //animation: loading-1 1.8s linear forwards;
    }
  }
  .progress-cir-value {
    display: flex;
    border-radius: 50%;
    font-size: 1rem;
    text-align: center;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-weight: 300;
    div {
      //margin-top: 10px;
    }
    span {
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}

/* This for loop creates the necessary css animation names 
Due to the split circle of progress-cir-left and progress-cir right, we must use the animations on each side. 
*/
@for $i from 1 through $howManySteps {
	$stepName: ($i*(100 / $howManySteps));

	//animation only the left side if below 50%
	@if $i <= ($howManySteps/2) { 
		.progress-cir[data-percentage="#{$stepName}"] {
			.progress-cir-right .progress-cir-bar {
				 animation: loading-#{$i} $animationTime linear forwards;
			}
			.progress-cir-left .progress-cir-bar {animation: 0;}
		}
	}
	//animation only the right side if above 50%
	@if $i > ($howManySteps/2)  {  
		.progress-cir[data-percentage="#{$stepName}"] {
			.progress-cir-right .progress-cir-bar {
				animation: loading-#{($howManySteps/2)} $animationTime linear forwards; //set the animation to longest animation
			}
			.progress-cir-left .progress-cir-bar {
      animation: loading-#{$i - ($howManySteps/2)} $animationTime linear forwards $animationTime;
    }
		}
	}
}

//animation
@for $i from 1 through ($howManySteps/2) { 
	$degrees: (180/($howManySteps/2));
	$degrees: ($degrees*$i);
	@keyframes loading-#{$i}{
    0%{
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate($degrees);
			transform: rotate(#{$degrees}deg);
    }
	}
}
.group-title{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.ico-dr-list {
  top: -2px;
  margin-right: 15px;
  width: 18px;
  position: relative;
}
.popup-end-sp {
  background: #e9e9e9;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  color: rgba(25, 25, 25, 0.87);
  font-size: 13px;
  line-height: 24px;
  padding: 5px 15px;
}
.company-infomation{
  .info-company{
    max-height: 100px;
    .job-detail-location{
      padding-top: 5px;
    }
  }
}
.rate-point{
 color: #ffad35;
 font-weight: bold;
  
}.img-star{
    width: 13px;
        height: 18px;
  
  }
  .nav-link.font-weight-bold{
padding:0 0 0.5rem 0 ;
margin-right: 1rem;
  }
  .tab-pane{
    p{
      .text-muted {
        color: #333333 !important;
    }
    }
  }
  #slider-job{
    
    .slick-slide {
      margin: 0 10px;
      img{
        width: 100%;
        border-radius: 8px;
        object-fit: cover;
        width: 350px;
        height: 210px;
      }
    }
 
    .slick-list {
      margin: 0 -10px;
    }
    
  }
  .list-top-company{
    .how-it-work-img{
      width: 100px;
      height: 100px;
    }
  }
  .company{
    .group-detail-description .job-detail-location .gach {
   border-left: 1px solid #e0e0e0;
   height: unset;
 }
   }

   .home-tab{
        .maps-type,.viet-review,.viec-lam{
          display: none;
        }
   }
   .profile-tab{
    .review-moi-nhat,.viet-review{
      display: none;
    }
    .maps-type,.viec-lam{
      display: block;
    }
}  

.contact-tab{
    .maps-type,.review-moi-nhat{
      display: none;
    }
    .viec-lam,.viet-review{
      display: block;
    }
}
.review-now{
  font-family: 'Roboto';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;
color: #4F4F4F;
}

.viec-lam{
  .job-list-box {
  border: none !important;
  border-bottom: 0px solid #e9e9e9 !important;
}
}
#myTab{
  li.nav-item{
    margin-right: 27px;
  }
}
#frmCvoPromptStars .btn_star {
  width: 20px;
  height: 20px;
  background: #ebe7e5;
  border-radius: 3px;
  padding: 0px 5px;
  display: inline-flex!important;
  position: relative;
  justify-content: center;
  i{
    font-size: 12px;
    align-self: center;
  }
}
.group-total-percent{
  display: grid;
  grid-template-columns: 1fr 1fr;
  span{
    font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 23px;
color: #000000;
  }
}
#col-right{
  .company-infomation-detail{
    margin-bottom: 27px!important;
  }
}
.group-title{
  small{
    color: #828282;
  }
}
.list-review .job-list-box{
  border-bottom: 1px solid #828282!important;
}