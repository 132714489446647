﻿//@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

html,
body {
  font-family: "Roboto", sans-serif;
  background-color: #fff;
}
.f-24 {
  font-size: 24px !important;
}
.f-22 {
  font-size: 22px !important;
}
.f-20 {
  font-size: 20px !important;
}
.f-18 {
  font-size: 18px !important;
}
.f-16 {
  font-size: 16px !important;
}
.f-14 {
  font-size: 14px !important;
}
.f-12 {
  font-size: 12px !important;
}
.f-10 {
  font-size: 10px !important;
}
.max-width-980 {
  max-width: 980px !important;
}
a {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  /* Gray 3 */

  color: #828282;
  &:hover {
    color: #f47421;
    text-decoration: underline;
  }
}
.text-dark {
  color: #333333 !important;
}
.group-listing-search {
  .group-search {
    max-height: 42px;
  }
}
.home-center {
  .home-registration-form {
    background: transparent;
  }
  .btn-search-home {
    height: 45px;
    font-weight: normal;
    line-height: 25px;
  }
  .group-search {
    border: 1px solid #f47421;
    box-sizing: border-box;
    box-shadow: 0px 4px 15px rgba(0, 60, 53, 0.08);
    display: flex;
    width: 100%;
    background-color: #fff;
    border-radius: 3px;
    justify-content: space-between;
    box-shadow: 0px 4px 15px rgba(0, 60, 53, 0.08);
    padding: 8px 0;

    .registration-form-box {
      flex-grow: 1;
      max-height: 30px;
      &.regae {
        border-right: none;
      }
      @include media-breakpoint-up(md) {
        &.regae {
          border-right: none;
        }
      }

      @include media-breakpoint-up(lg) {
        &.regae {
          border-right: 1px solid #e9e9e9;
        }
      }

      @include media-breakpoint-up(xl) {
        &.regae {
          border-right: 1px solid #e9e9e9;
        }
      }

      .registration-input-box {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        border: none;
        color: #828282;
        height: 30px !important;
        padding: 0px 6px 0px 30px;
        min-width: 383px;

        @include media-breakpoint-up(md) {
          min-width: 383px;
        }

        @include media-breakpoint-up(lg) {
          min-width: 360px;
        }

        @include media-breakpoint-up(xl) {
          min-width: 360px;
        }
      }
      .selectize-dropdown,
      .selectize-input,
      .selectize-input input {
        font-size: 14px;
        height: 30px;
        border: none;
        color: #828282;
        padding: 0px 6px 0px 35px;
        max-width: 190px;
        @include media-breakpoint-up(md) {
          min-width: 200px;
        }

        @include media-breakpoint-up(lg) {
          min-width: 100%;
        }

        @include media-breakpoint-up(xl) {
          min-width: 100%;
        }
      }
    }
    flex-wrap: wrap;
    @include media-breakpoint-up(md) {
      flex-wrap: wrap;
    }

    @include media-breakpoint-up(lg) {
      flex-wrap: none;
    }

    @include media-breakpoint-up(xl) {
      flex-wrap: none;
    }
    .registration-form-box {
      input {
        border: none;
        border-radius: 0;
        line-height: 1.8;
        color: #828282;
        height: 45px;
      }
      #txtSearchList {
        height: 39px;
      }
      select {
        border: none;
        border-radius: 0;
      }
      .selectize-control.single .selectize-input,
      .selectize-control.single .selectize-input input {
        border-radius: 0px;
      }
    }
  }
  .carrer-list {
    margin-top: 37px;
    a {
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      color: #505050;
      margin-right: 25px;
    }
  }
}
.bg-home {
  padding: 120px 0px 64px 0px;

  background: none;
  background-color: #fff8f3;

  .title-heading {
    margin-bottom: 50px;
    .heading {
      font-style: normal;
      font-weight: normal;

      color: #f47421;

      font-size: 17px !important;
      line-height: 17px;

      @include media-breakpoint-up(md) {
        font-size: 22px !important;
        line-height: 26px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 32px !important;
        line-height: 37px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 32px;
        line-height: 37px;
      }
    }
  }
  .bg-overlay {
    background-color: #fff8f3;
  }
}

.how-it-work-box {
  min-height: 306px;
  display: flex;
  flex-direction: column;

  .how-it-work-img {
    background-color: #fff;

    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 4px;
  }
  h5 {
    margin-bottom: 0;
  }
  .group-info {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    margin-top: auto;
    justify-content: center;
    a {
      padding: 0px 5px;
      &:first-child {
        border-right: 1px solid #e9e9e9;
      }
      &:last-child {
        padding: 0px 5px 0 10px;
      }
    }
  }
}
h4 {
  &.title {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
    color: #333333;
  }
}
hr {
  border-top: 1px solid #f47421;
}
.section-title {
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    color: #333333;
  }
}
.register-get-carrer {
  .section-title {
    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 26px;
      color: #333333;

      @include media-breakpoint-up(md) {
        font-size: 22px;
      }

      @include media-breakpoint-up(lg) {
        font-size: 22px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 22px;
      }
    }
  }
  .bg-banner-register {
    background-image: url("../images/register-banner.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    min-height: 500px;
    border-radius: 6px;
  }
  .form-register {
    .form-group {
      label {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        color: #4f4f4f !important;
      }
    }
  }
  .form-group .form-control {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #828282;
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    border-radius: 3px;
    height: 40px;
  }
  .cus-input {
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    border-radius: 3px;
    .input-group-text {
      border-radius: 0;
      background-color: #f8f8f8;
      border: none;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #4f4f4f;
      i {
        margin-right: 4px;
      }
    }
  }
  .view-cv-template {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-decoration-line: underline !important;
    color: #2f80ed;
    margin-top: 10px;
  }
  .custom-control-label {
    font-style: normal;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 18px !important;

    color: #4f4f4f !important;
    &:before {
      top: 0px;
    }
    &:after {
      top: 0px;
    }
  }
}
.validate {
  border: 1px solid #f47421 !important;
}

.modal-login-register {
  &.show {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .group-bg {
    background-image: url("../images/bg-login.png");
    background-size: cover;
    background-position: center center;

    display: flex;
    justify-content: center;
    height: calc(100vh - 50px);

    img {
      align-self: center;
    }
  }
  .modal-content {
    border-radius: 0;
    .modal-body {
      padding: 0;
    }
  }
  .group-info {
    .group-login {
      .group-login-detail {
        align-self: center;
        h2 {
          font-style: normal;
          font-weight: 500;
          font-size: 26px;
          line-height: 30px;
          letter-spacing: 0.03em;
          color: #333333;
        }
        h5 {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: #828282;
        }
      }
    }
  }
  .white-padding {
    padding: 15px;
    border: 1px solid transparent;
    .popular-posts .post-block-item {
      margin-bottom: 0px;
      position: relative;
    }
    &.active,
    &:hover {
      border: 1px solid #f47421 !important;
    }
    cursor: pointer;
  }
  .post-block-item.style-1 {
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #333333;
    }
    .post-block-meta {
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #828282;
      }
    }
    .post-block-thumb {
      img {
        height: 79px;
      }
    }
  }
  .group-button-back {
    text-align: left;
    position: absolute;
    bottom: 20px;
  }
}

i.fa-heart {
  cursor: pointer;
  &.active {
    font-weight: 900;
    color: #f47421;
  }
}
.view-all {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #f47421 !important;
}
.job-list-desc {
  h6 {
    margin-bottom: 0;
    a {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: #333333;
    }
  }
}
.apply-job {
  p {
    &.text-muted {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #333333;
    }
  }
}

#frmCvoPromptStars {
  .cvoStarsRating {
    unicode-bidi: bidi-override;
    direction: rtl;
  }
  .cvoStarsRating > span.rated,
  .cvoStarsRating > span.rated ~ span {
    color: #fff;
    background: #f47421;
  }
  .cvoStarsRating > span {
    color: #fff;
    cursor: pointer;
    display: inline-block;
    position: relative;
  }
  .btn_star {
    width: 30px;
    height: 30px;
    background: #ebe7e5;
    border-radius: 3px;
    padding: 5px;
  }
}
#frmCvoPromptStars .cvoStarsRating:hover > span.rated,
#frmCvoPromptStars .cvoStarsRating:hover > span.rated ~ span {
  color: #fff;
}
#frmCvoPromptStars .cvoStarsRating:hover > span:hover,
#frmCvoPromptStars .cvoStarsRating:hover > span:hover ~ span,
#frmCvoPromptStars .cvoStarsRating:hover > span.rated:hover,
#frmCvoPromptStars .cvoStarsRating:hover > span.rated:hover ~ span {
  background: #f47421;
}
.group-choice {
  a {
    background: #f5f7f9;
    border-radius: 6px;
    margin-right: 10px;
    &.active {
      border: 1px solid #f47421;
    }
    &:hover {
      border: 1px solid #f47421;
    }
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #303030;
    }
  }
}

.header h2 {
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #f47421;
}
.header ul {
  border: 2px dashed #ccc;
  padding: 10px;
}
.header ul li {
  list-style: none;
  font-size: 16px;
  color: #666;
  line-height: 30px;
}
.steps .share-fb {
  text-align: center;
}
.btn-use {
  margin-top: 20px;
  margin-bottom: 20px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  line-height: normal;
  display: inline-block;
  letter-spacing: 1px;
  padding: 12px 20px;
  font-size: 16px;
  width: 250px;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}

.btn-taocvonline-primary {
  background-color: #f47421;
  color: #fff;
}
.steps .share-fb span {
  font-size: 15px;
  line-height: 25px;
}
.btn-taocvonline-facebook {
  color: #fff !important;
  background-color: #3b5998 !important;
  border-color: #3b5998 !important;
}

.data-search {
  .select2-container--default
    .select2-selection--single
    .select2-selection__arrow
    b {
    border-color: unset;
    border-style: unset;
    border-width: unset;
    height: 0;
    left: 50%;
    margin-left: -4px;
    margin-top: -2px;
    position: absolute;
    top: 50%;
    width: 0;
  }
  .select2-container--default .select2-selection--single {
    background-color: #fff;
    border: none;
    border-radius: unset;
    padding-left: 25px;
    padding-top: 2px;
  }
  &:focus-visible {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  .typeahead__query {
    position: relative;
    z-index: 0;
    width: 100%;
    padding: 0 25px;
    margin-top: -7px;
  }
  .typeahead__list > .typeahead__group > a,
  .typeahead__list > .typeahead__group > a:hover,
  .typeahead__list > .typeahead__group > a:focus,
  .typeahead__list > .typeahead__group.active > a {
    cursor: default;
    color: #f47427;
    background: #ffffff;
    display: block;
    padding: 0.5rem 0.75rem;
    clear: both;
    text-decoration: none;
    font-weight: bold;
  }
  .typeahead__list > .typeahead__group {
    border-color: #cccccc;
  }
  .typeahead__list > .typeahead__group:first-child {
    border-top: solid 1px #f47421;
  }
}

element.style {
}
.typeahead__container * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
}
*,
::after,
::before {
  box-sizing: border-box;
}
.typeahead__list .typeahead__item:not([disabled]) > a:hover,
.typeahead__list .typeahead__item:not([disabled]) > a:focus,
.typeahead__list .typeahead__item:not([disabled]).active > a,
.typeahead__dropdown .typeahead__dropdown-item:not([disabled]) > a:hover,
.typeahead__dropdown .typeahead__dropdown-item:not([disabled]) > a:focus,
.typeahead__dropdown .typeahead__dropdown-item:not([disabled]).active > a {
  background-color: #fff;
  color: #757575;
}
.typeahead__list .typeahead__item > a,
.typeahead__dropdown .typeahead__dropdown-item > a {
  color: #757575;
  text-decoration: none;
}
#topnav .navigation-menu > li .submenu li {
  a {
    text-transform: capitalize;
    padding: 10px 30px;
  }
}
.list-category-account {
  padding: 0 20px;
  li {
    margin-bottom: 10px;
  }
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #f47421;
  border: 1px solid #f47421;
  padding: 0px 5px;
  color: #fff;
}
.select2-container--default .select2-selection--multiple {
  padding: 3px 0 2px;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  margin-top: 3px;
  padding: 0 5px;
}
.select2-container--default:focus {
  border-color: #f47421;
}

.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: transparent transparent #fff transparent;
}

.select2-container--default .select2-search__field:focus {
  border: 1px solid #f47421;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: #f47421;
}

.select2-container--default .select2-selection--multiple {
  padding: 3px;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #f47421;
  border: 1px solid #f47421;
  padding: 0px 15px;
  color: #fff;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice
  span {
  color: #fff;
}

.select2-container--default
  .select2-selection--multiple
  .select2-search__field {
  border: none;
}

.select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border: 1px solid #f47421;
}

.select2-container--default .select2-selection--single {
  color: #fff;
  height: auto !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  background-color: #f47421;
  color: #fff;
  padding: 3px 30px 3px 20px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: 10px;
  right: 15px;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-color: #fff transparent transparent transparent;
}
.select2-container .select2-search--inline .select2-search__field {
  height: 26px !important;
}
.select2-container--default .select2-selection--multiple {
  border: 1px solid #e9e9e9;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__remove {
  border-right: 1px solid #fff;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice__display {
  cursor: default;
  padding-left: 5px;
}

.user-card-full {
  overflow: hidden;
}

.user-card-full .user-profile {
  border-radius: 5px 0 0 5px;
}

.user-card-full .social-link li {
  display: inline-block;
}

.user-card-full .social-link li a {
  font-size: 20px;
  margin: 0 10px 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.user-card-full .social-link li a i {
  color: #666;
}

.user-card-full .social-link li a i.facebook:hover {
  color: #3b5997;
}

.user-card-full .social-link li a i.twitter:hover {
  color: #42c0fb;
}

.user-card-full .social-link li a i.instagram:hover {
  color: #aa7c62;
}

.user-card-full .social-link li a i.youtube:hover {
  color: #e0291d;
}
.card-block {
  padding: 1.25rem;
}
.bg-c-lite-green {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f47421),
    to(#01dbdf)
  );
  background: linear-gradient(to right, #f47421, #01dbdf);
}
.f-w-600 {
  font-weight: 600;
}
.b-b-default {
  border-bottom: 1px solid #e0e0e0;
}
.card .card-block p {
  line-height: 25px;
}
.text-muted {
  color: #919aa3 !important;
}

.f-w-400 {
  font-weight: 400;
}
.m-b-10 {
  margin-bottom: 10px;
}
#topnav .navigation-menu .has-submenu .menu-arrow {
  border: solid rgba(255, 255, 255, 0.8);
  border-radius: 0.5px;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: unset;
  -webkit-transform: unset;
  transform: unset;
  position: absolute;
  -webkit-transition: unset;
  transition: unset;
  right: -1px;
  top: 30px;
}

.selectize-control.single .selectize-input::after {
  border: solid rgba(128, 128, 128, 0.8);
  border-radius: 0.5px;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: unset;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  -webkit-transition: unset;
  transition: unset;
  margin-top: 0;
  right: 17px;
  top: 10px;
  width: 9px;
  height: 9px;
}
.selectize-control.single .selectize-input::before {
  border: solid rgba(128, 128, 128, 0.8);
  border-radius: 0.5px;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: unset;
  -webkit-transform: rotate(227deg);
  transform: rotate(227deg);
  position: absolute;
  margin-top: 0;
  right: 17px;
  top: 10px;
  width: 9px;
  background: transparent;
  height: 9px;
}
.selectize-input.dropdown-active {
  &::before {
    content: " ";
    display: block;
    position: absolute;
    background: transparent;
    height: 1px;
    bottom: unset;
    left: unset;
  }
  ::after {
    border: 0px solid #fff !important;
    border-color: transparent !important;
  }
}
.selectize-control.single .selectize-input.dropdown-active:after {
  margin-top: -4px;
  border-width: 0 5px 5px 5px;
  border-color: transparent;
}

#navigation {
  .navigation-menu {
    .menu-arrow {
      content: " " !important;
      display: block !important;
      position: absolute !important;
      top: 40px !important;
      right: 0px !important;
      margin-top: -3px !important;
      width: 0 !important;
      height: 0 !important;
      border-style: solid;
      border-width: 5px 5px 0 5px !important;
      border-color: #808080 transparent transparent transparent !important;
    }
  }
}
.buy-button {
  .navigation-menu {
    .menu-arrow {
      content: " " !important;
      display: block !important;
      position: absolute !important;
      top: 34px !important;
      right: 0px !important;
      margin-top: -3px !important;
      width: 0 !important;
      height: 0 !important;
      border-style: solid;
      border-width: 5px 5px 0 5px !important;
      border-color: #808080 transparent transparent transparent !important;
    }
  }
}
.has-submenu {
  a {
    text-transform: none !important;
  }
}
#topnav .navigation-menu > li .submenu li a {
  color: #333;
}
.group-detail-listing.page-next-level .page-next li:after {
  content: "/" !important;
  font-size: 16px;
  color: #828282;
  font-family: "Material Design Icons";
}
.btn {
  letter-spacing: unset;
}
.typeahead__container ::-webkit-input-placeholder {
  /* color: inherit; */
  opacity: 1 !important;
  color: #828282 !important;
}
.typeahead__list .typeahead__item > a,
.typeahead__dropdown .typeahead__dropdown-item > a {
  color: #828282 !important;
}
.top-home-company {
  margin-bottom: 14px;
}
.list-top-company {
  .item-top-company {
    margin-bottom: 22px;
    max-width: 255px;
    max-height: 310px;
  }
}
.job-details-desc-item {
  .row {
    height: 85px;
  }
}

.typeahead__list .typeahead__item:not([disabled]) > a:hover,
.typeahead__list .typeahead__item:not([disabled]) > a:focus,
.typeahead__list .typeahead__item:not([disabled]).active > a,
.typeahead__dropdown .typeahead__dropdown-item:not([disabled]) > a:hover,
.typeahead__dropdown .typeahead__dropdown-item:not([disabled]) > a:focus,
.typeahead__dropdown .typeahead__dropdown-item:not([disabled]).active > a {
  background-color: #f5f7f9;
  color: #828282;
}
.data-search .typeahead__list > .typeahead__group:first-child {
  border-top: solid 0px #f47421;
}
.typeahead__list > .typeahead__group {
  border-color: #cccccc;
  font-weight: bold;
}
.data-search .typeahead__list > .typeahead__group {
  border-bottom: 1px solid #f47427 !important;
}
.typeahead__list > li.typeahead__group + li.typeahead__item {
  border: 0 solid #cccccc !important;
}

.typeahead__list,
.typeahead__dropdown {
  margin: 5px 0 0 !important;
}
.list-top-company {
  .how-it-work-box {
    h5 {
      padding: 0 21px;
    }
  }
}

.social-icon li a:hover {
  background-color: unset;
  border-color: #f47421 !important;
  color: #f47421 !important;
}

.input-group-focus {
  border-radius: 4px;
  -webkit-transition: box-shadow ease-in-out 0.15s;
  transition: box-shadow ease-in-out 0.15s;
}
.input-group-addon {
  -webkit-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s;
}
.input-group.input-group-focus {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(244, 116, 39, 1) !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(244, 116, 39, 1) !important;
}
.has-error.input-group.input-group-focus,
.has-error .input-group.input-group-focus {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483 !important;
}
.has-warning.input-group.input-group-focus,
.has-warning .input-group.input-group-focus {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168 !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168 !important;
}
.has-success .input-group.input-group-focus,
.has-success .input-group.input-group-focus {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b !important;
}
.input-group-focus input:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.input-group-focus .input-group-addon {
  border-color: #66afe9 !important;
}
.has-error .input-group-addon {
  border-color: #843534 !important;
}
.has-success .input-group-addon {
  border-color: #2b542c !important;
}
.has-warning .input-group-addon {
  border-color: #66512c !important;
}
.login-form {
  .custom-control-input:focus ~ .custom-control-label::before,
  .form-control:focus,
  .nice-select:active,
  .nice-select.open,
  .nice-select:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: transparent !important;
  }
}
.job-details-desc-item i.fa-star {
  color: #ffab36;
  font-weight: bold;
}

.job-details-desc-item span {
  color: #ffab36;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
}

.item-gellary {
  display: inline-flex;
  margin-right: 8px;
  &:first-child {
    margin-right: 0px;
    .item1 {
      width: 300px;
      height: 300px;

      img {
        width: 100%;
        border-radius: 3px;
        object-fit: cover;
      }
    }
  }
  &:last-child {
    margin-right: 0px;
  }
  .item1 {
    display: flex;
    width: 65px;
    height: 65px;

    img {
      width: 100%;
      border-radius: 3px;
      object-fit: cover;
    }
  }
}

.slick-slider-child .item-inner {
  height: 65px;
  width: 65px;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 3px;
}
.list-inline-item {
  p {
    i {
      margin-right: 3px;
    }
  }
}
.class-footer-f {
  padding-left: 95px;
}
.padding-80 {
  padding-bottom: 80px !important;
}

.demo-gallery > ul {
  margin-bottom: 0;
}
.demo-gallery > ul > li {
  float: left;
  margin-bottom: 15px;
  margin-right: 20px;
  width: 200px;
}
.demo-gallery > ul > li a {
  border: 3px solid #fff;
  border-radius: 3px;
  display: block;
  overflow: hidden;
  position: relative;
  float: left;
}
.demo-gallery > ul > li a > img {
  -webkit-transition: -webkit-transform 0.15s ease 0s;
  -moz-transition: -moz-transform 0.15s ease 0s;
  -o-transition: -o-transform 0.15s ease 0s;
  transition: transform 0.15s ease 0s;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  height: 100%;
  width: 100%;
}
.demo-gallery > ul > li a:hover > img {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
}
.demo-gallery > ul > li a:hover .demo-gallery-poster > img {
  opacity: 1;
}
.demo-gallery > ul > li a .demo-gallery-poster {
  background-color: rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: background-color 0.15s ease 0s;
  -o-transition: background-color 0.15s ease 0s;
  transition: background-color 0.15s ease 0s;
}
.demo-gallery > ul > li a .demo-gallery-poster > img {
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
  opacity: 0;
  position: absolute;
  top: 50%;
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
}
.demo-gallery > ul > li a:hover .demo-gallery-poster {
  background-color: rgba(0, 0, 0, 0.5);
}
.demo-gallery .justified-gallery > a > img {
  -webkit-transition: -webkit-transform 0.15s ease 0s;
  -moz-transition: -moz-transform 0.15s ease 0s;
  -o-transition: -o-transform 0.15s ease 0s;
  transition: transform 0.15s ease 0s;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  height: 100%;
  width: 100%;
}
.demo-gallery .justified-gallery > a:hover > img {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
}
.demo-gallery .justified-gallery > a:hover .demo-gallery-poster > img {
  opacity: 1;
}
.demo-gallery .justified-gallery > a .demo-gallery-poster {
  background-color: rgba(0, 0, 0, 0.1);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: background-color 0.15s ease 0s;
  -o-transition: background-color 0.15s ease 0s;
  transition: background-color 0.15s ease 0s;
}
.demo-gallery .justified-gallery > a .demo-gallery-poster > img {
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
  opacity: 0;
  position: absolute;
  top: 50%;
  -webkit-transition: opacity 0.3s ease 0s;
  -o-transition: opacity 0.3s ease 0s;
  transition: opacity 0.3s ease 0s;
}
.demo-gallery .justified-gallery > a:hover .demo-gallery-poster {
  background-color: rgba(0, 0, 0, 0.5);
}
.demo-gallery .video .demo-gallery-poster img {
  height: 48px;
  margin-left: -24px;
  margin-top: -24px;
  opacity: 0.8;
  width: 48px;
}
.demo-gallery.dark > ul > li a {
  border: 3px solid #04070a;
}
.home .demo-gallery {
  padding-bottom: 80px;
}

.navbar-cat {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  li {
    float: left;
  }

  li a {
    display: block;
    color: #828282;
    text-align: center;
    padding: 3px 25px;
    text-decoration: none;
  }

  li a {
    &:hover {
      color: #f47421;
    }
    &.active {
      color: #f47421;
    }
  }
}
.group-comment {
  .media-body {
    display: grid;
    grid-template-columns: 1fr 30px;
  }
}
.tagcloud {
  a {
    border: 1px solid #828282 !important;
    background-color: #fff !important ;
    color: #828282;
    &:hover {
      border: 1px solid #f47421 !important;
    }
  }
}
.form-group textarea {
  height: 60px !important;
}
.input-group > .custom-file {
  background-color: #fff;
}
.form-register .input-group.cus-input {
  border: 1px solid #e9e9e9 !important;
}
.review-company {
  .gach {
    height: 35px !important;
  }
}

.background-img-blog {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  min-height: 233px;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.dropdown-share {
  button {
    border: none;
    background-color: transparent;
  }
}
.job-detail-desc {
  h4 {
    &.header-job-description {
      &.mt-5 {
        margin-top: 36px !important;
      }
    }
  }
}
.company-info {
  .job-detail-location {
    .gach {
      height: 35px !important;
    }
  }
}
.group-detail-description1 .job-detail-location .job-details-desc-item div {
  display: block;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 5px;
  color: #828282;
}
.group-contact-job span {
  color: #f47421;
}
.group-contact-job a {
  display: block;
  margin-bottom: 10px;
  cursor: pointer !important;
}
.custom-file {
  span {
    position: absolute;
    left: 10px;
    color: #828282;
    font-size: 14px;
  }
}
#uploadFileHome {
  #inputGroupFileAddon01,
  #file-upload-button {
    cursor: pointer !important;
  }

  .custom-file-input {
    position: absolute !important;
    left: -120px;
    cursor: pointer !important;
  }
  cursor: pointer !important;
}
::-webkit-file-upload-button {
  cursor: pointer;
}
.group-comment-detail {
  background: #f5f7f9;
  border-radius: 3px;
  padding: 5px 10px;
}
.blog {
  max-width: 352px;
  max-height: 450px;
}
.blog .content .title {
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
}
.blog .content h4 {
  min-height: 45px;
}
.blog .content .p-short-description {
  font-size: 14px;
  line-height: 16px;
  min-height: 46px;
}
html {
  --lh: 1.4rem;
  line-height: var(--lh);
}

.line-clamp {
  --max-lines: 3;
  position: relative;
  max-height: calc(16px * var(--max-lines));
  overflow: hidden;
}

.line-clamp::after {
  content: "";
  position: absolute;
  inset-inline-end: 0; /* "right" */
  width: 1rem;
  height: 1rem;
  background: white;
}
.readmore {
  color: #828282 !important;
}
.page-blog {
  h4.header-job-description {
    text-transform: uppercase;
    border-left: 5px solid #f87320;
    padding-left: 10px;
  }
}
.btn-hover {
  cursor: pointer;
  color: #828282;
  i {
    color: #828282;
  }
  &:hover {
    color: #f87320;
    i {
      color: #f87320;
    }
  }
}
.group-comment-detail {
  h6 {
    a {
      font-weight: bold;
    }
  }
}
.group-title-comment {
  display: flex;
  p {
    padding-top: 2px;
  }
}

.group-detail-description {
  .cus-detail-for-gach {
    .job-detail-location .gach {
      border-left: 1px solid #e0e0e0;
      height: 62px;
      margin-top: 5px;
    }
  }
}
.notification-icon {
  .icon-noti {
    width: 27px;
    height: 27px;
    margin-top: 5px;
  }
  .count-noti {
    width: 16px;
    height: 16px;
    background-color: #eb5757;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 15px;
    .count-notifi {
      font-size: 10px;
      color: #fff;
      align-self: center;
    }
  }
}
.readed {
  opacity: 0.6;
}
.btn-read-notification {
  cursor: pointer;
}
#topnav .buy-button .navigation-menu > li .submenu li a {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 15px !important;
  img {
    width: 24px;
    height: 24px;
  }
}
.list-category-account {
  padding: 0 0px;
  li {
    list-style: none;
    a {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}
.job-details-desc-item {
  p.font-weight-bold {
    min-height: 50px;
  }
}
.job-list-box:hover {
  box-shadow: unset;

  transform: unset;
  .job-list-desc {
    h6 {
      a {
        color: #f47421 !important;
      }
    }
  }
}
.typeahead__cancel-button {
  line-height: 1.825 !important;
}
.fa-page {
  .home-desc-center {
    display: block;
  }
  .help-page {
    strong {
      span {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 21px;
        line-height: 25px;

        color: #333333;
      }
    }
    i.header {
      font-family: Roboto;
      font-style: italic;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      text-align: justify;

      color: #4f4f4f;
    }
    .card-header {
      border-bottom: 1px solid #c4c4c4;
      padding: 0px 0;
    }

    .btn-link {
      color: transparent;
      padding: 8px 0;
      &:hover {
        color: transparent;
        text-decoration: none;
      }
    }
  }
}
.company-logo-img {
  img {
    width: 65px;
    height: 65px;
    object-fit: contain;
    border-radius: 4px;
  }
}
.popover-body {
  li {
    list-style: none;
    a {
      cursor: pointer;
      &:hover {
        color: #f47421;
      }
    }
  }
}
.text-edit {
  border: 1px dashed #f47421;
  padding-left: 5px;
  &:focus {
    cursor: pointer;
    border: 1px dashed #f47421;
  }
  &:focus-visible {
    outline: none !important;
  }
}
.div-text-comment {
  position: relative;
  .group-action-comment {
    position: absolute;
    right: 0;
    top: 20px;
    i {
      margin-left: 10px;
      cursor: pointer;
      &.fa-times {
        color: red;
      }
      &.fa-check {
        color: green;
      }
    }
  }
}
h4.header-job-description {
  font-size: 18px;
}
.text-location {
  color: #828282;
}
.btn-tag.hot {
  color: #fff;
  background-color: #eb5757;
}

.btn-tag {
  padding: 1px 3px;
  border-radius: 3px;
  font-size: 12px;
  width: 33px;
  height: 16px;
  text-transform: uppercase;
  margin-right: 5px;
}
.submenu li.active {
  a {
    color: #f47421 !important;
  }
}

.modal-backdrop {
  position: fixed;
  top: calc(100% - 92%);
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.group-detail-description {
  .modal {
    top: calc(50% - 10%);
  }
  #modalEpired {
    h2 {
      font-size: 20px;
      text-align: center;
    }
    a {
      color: #f47421 !important;
      text-decoration: underline !important;
    }
  }
}
.save-company-list {
  position: relative;
  a.btn-favorite {
    font-weight: bold;
    position: absolute;

    top: 10px;
    right: 10px;
  }
  .how-it-work-box h5 {
    font-size: 16px;
  }

  .how-it-work-box {
    .group-info {
      font-size: 14px;
    }
    p {
      font-size: 14px;
    }
  }
}
.lblUpload {
  cursor: pointer;
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary {
  color: #fff;
  background: #f47427 !important;
}
.cusjoblist {
  position: relative;
  .btn-deletefavorite {
    position: absolute;
    top: 5px;
    right: 10px;
  }
}

#listProfile {
  .job-list-desc h6 a {
    font-size: 16px;
  }
  .text-location {
    font-size: 14px;
  }
}
.btn-favorite {
  cursor: pointer;
  i {
    cursor: pointer;
  }
}
.job-detail-content {
  .save-company-list {
    max-width: 255px;
    max-height: 310px;
    border: 1px solid #e9e9e9;
    border-radius: 8px !important;
    .how-it-work-box {
      min-width: unset !important;
    }
  }
}
.list-review {
  .text-muted {
    color: #333333 !important;
  }
}
.blog-details-desc table {
  width: 100% !important;
}
.lbl-required:after {
  content: " *";
  color: red;
}
.btn.btn-primary:focus,
.btn.btn-primary:active,
.btn.btn-primary.active,
.btn.btn-primary.focus {
  color: #fff !important;
  border-color: #f47421 !important;
  background-color: #f47421 !important;
}

.search-city {
  ._1R7LAU .icon-arrow-down,
  ._1R7LAU .icon-arrow-up {
    font-size: 0.625rem;
    fill: #636363;
    margin-left: 5px;
    pointer-events: none;
  }
  svg:not(:root) {
    overflow: hidden;
  }
  .shopee-svg-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: currentColor;
    position: relative;
  }
}
