@media (min-width: 1568px){
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1110px;
}

}
.max-width-1440{
    max-width: 1440px;
}
.max-width-950{
    max-width: 950px;
}