
@import "variables";


/*- Bootstrap
/*- ---------------------------------------------- */
// Import Bootstrap variables after customization for use below
@import "../vendor/bootstrap/scss/functions"; // from bootstrap node_modules
@import "../vendor/bootstrap/scss/variables"; // from bootstrap node_modules
@import "global";

/*- Template
/*- ---------------------------------------------- */
@import "template/template";
//@import "custom";



.btn {
   
    border-radius: 3px;
}
.rounded {
    border-radius: 3px !important;
}
.selectize-control.single .selectize-input, .selectize-control.single .selectize-input input {
    border-radius: 3px;
}
.h5, h5 {
    font-size: 1.125rem;
}
a.text-primary:focus, a.text-primary:hover {
    color: #FDE3D3!important;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;

    border-color: #F47421;
}
h4{
    font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 19px;



color: #333333;
}