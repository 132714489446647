.group-listing-search {
  padding: 75px 0px 0px 0px;
  .home-center {
    .carrer-list {
      margin-top: 12px;
      a {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;

        color: #303030;

        opacity: 0.7;
      }
    }
  }
}
ul {
  li {
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #828282;
      &.ogrange {
        color: #f47421 !important;
      }
      &.dark {
        color: #828282;
      }
    }
  }
}
.group-listing {
  .left-sidebar {
    .text-search-listing {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #333333;
    }
    #accordionExample {
      border: 1px solid #e9e9e9;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 15px;
      .card {
        border: none;
        &:last-child {
          .card-body {
            border-bottom: none;
          }
        }
      }
      .collapse-text {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #333333;
      }
      .custom-control {
        margin: 11px 0;
      }
    }
    .card-body {
      border-bottom: 1px dashed #e2e2e2;
      .text-muted {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #4f4f4f;
      }
    }
    .card-header {
      border-bottom: none;
      padding: 0;
    }
    a.job-list[data-toggle="collapse"]:before {
      content: "\f078";
      display: block;
      font-family: "Font Awesome 5 Pro";

      position: absolute;
      right: 10px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 10px;

      color: #828282;
    }
    a.job-list[data-toggle="collapse"].collapsed:before {
      content: "\f077";
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
      font-family: "Font Awesome 5 Pro";
    }
  }
  .job-list-box {
    .job-list-desc {
      h6 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #333333;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;

        color: #828282;
        a {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;

          color: #828282;
        }
      }
   
    }
    .job-list-button {
      span {
        &.text-location {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          text-align: right;

          color: #828282;
        }
      }
    }
  }
  .sort-button {
    .input-group-text {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;

      color: #828282;
      background-color: #fff;
      border: 0 solid #ced4da;
      border-radius: 0.25rem;
    }
  }
  .nice-select {
    border: 0 solid #ced4da;
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #f47421;
    }
  }
}

.pagination.job-pagination .page-link {
  border-radius: unset !important;
  width: unset;
  color: #8492a6;
}
.pagination .page-item.active .page-link {
  background-color: #fff;
  border-color: #fff;
  color: #f47421 !important;
}
.page-link {
  border: 0 solid #dee2e6;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #b6bac2;
}

.group-detail-listing {
  .testi-img {
    background-position: center center;
    background-repeat: no-repeat;

  }

  padding: 75px 0px 0px 0px;
  &.page-next-level {
    .page-next {
      margin-top: 15px;
      li {
        a,
        span {
          padding-right: 8px;
          -webkit-transition: all 0.5s ease;
          transition: all 0.5s ease;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          color: #828282 !important;
        }
        &:after {
          content: "\f142";
          font-size: 16px;
          color: #828282;
          font-family: "Material Design Icons";
        }
      }
    }
  }
}
.btn-out-line {
  background: #ffffff;
  border: 1px solid #e9e9e9;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #303030;
  padding: 8px 15px;
}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color: #f47421!important;
  border-color: #f47421!important;
  i{
    color:  #fff!important;
  }
}
.group-detail-description {
  .job-detail-general {
    .job-detail-location {
      border-top: none !important;

      .job-details-desc-item {
        div {
          display: flex;
        }
        .group-icon {
          border: 1px solid #bdbdbd;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          padding: 10px;
          i {
            align-self: center;
          }
        }
        .group-text {
          display: unset;
          padding: 5px 0 0 5px;

          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #828282;
        }

        .general-value {
          display: unset;
          padding: 5px 0 0 5px;

          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #333;
        }
        .text-orange {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          color: #f47421;
        }
      }
    }
  }

  .btn-about-company {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #303030;
  }
  .cus-job-detail-location {
    display: grid;
    grid-template-columns: 1fr 20px 1fr;
  }
  .job-detail-location {
    border-top: 1px dashed #e2e2e2 !important;
    grid-template-columns: unset;
    @include media-breakpoint-up(md) {
      grid-template-columns: unset;
    }

    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr 20px 1fr;
    }

    @include media-breakpoint-up(xl) {
      grid-template-columns: 1fr 20px 1fr;
    }
    .gach {
      border-left: 1px solid #e0e0e0;
      height: 82px;
    }
 
    .job-details-desc-item {
      div {
        display: block;
        width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 5px;
        color: #828282;
      }
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
      }
    }
  }
  .group-image {
    .image-company {
      max-width: 65px;
      width: 65px;
      height: 65px;
      object-fit: contain;
    }
    margin-bottom: 15px;
  }
  .title-company-job-detail {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #333333;
    .des-title-company-job-detail {
      font-size: 14px;
      line-height: 22px;

      color: #828282;
    }
    p {
      font-weight: 400;
    }
  }
  .tagcloud > a {
    background: #e9ecef;
    color: #3c4858;
    display: inline-block;
    font-size: 9px;
    letter-spacing: 1px;
    margin: 5px 10px 5px 0;
    padding: 8px 12px;
    text-transform: uppercase;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .job-detail-desc {
    hr {
      border-top: 1px solid #e0e0e0;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 10px;
      color: #333333;
    }
  }
  .rounded {
    border-radius: 8px !important;
  }
  .job-detail {
    background-color: #fff;
    .job-detail-title {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      color: #000000;
    }
  }
  .border {
    border: 1px solid #e9e9e9 !important;
  }

  .btn-save-job {
    /* White */

    background: #ffffff;
    /* Border line */

    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #303030;
    padding: 8px 15px;
  }
  .btn-apply-cv {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
  }
  .header-job-description {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-top: 20px;
  }
  .group-job-related {
    .job-list-desc {
      h6 {
        a {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: #333333;
        }
      }
    }
  }
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #f47421;
  border-color: #f47421;
}
.title-muted {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #4f4f4f;
}
.form-register {
  .form-group .form-control {
    border-radius: 4px;
  }
  label.text-muted {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    color: #333333!important;
  }
  .input-group.cus-input {
    background: #f5f7f9;
    border: 1px dashed #d3d6da;
    box-sizing: border-box;
  }
  .group-upload {
    background: #f5f7f9;
    border: 1px dashed #d3d6da;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 30px;
    border-radius: 4px;
    i {
      font-weight: 300;
      font-size: 22px;
      line-height: 20px;

      color: #828282;
    }
    .text-upload {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;

      font-weight: bold;
      margin-top: 10px;

      color: #4f4f4f;
    }
  }

  .upload-area:hover {
    cursor: pointer;
  }

  .upload-area h1 {
    text-align: center;
    font-weight: normal;
    font-family: sans-serif;
    line-height: 50px;
    color: darkslategray;
  }

  #uploadfile-apply {
    display: none;
  }

  .size {
    font-size: 12px;
  }
}
.group-uploaded {
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  min-height: 60px;
  padding: 8px;
  #thumbnail {
    display: grid;
    grid-template-columns: 40px 1fr 1fr;
    .group-name-size {
      display: flex;
      flex-direction: column;
    }
    .group-button-apply {
      display: flex;
      justify-content: flex-end;
      a {
        margin-right: 5px;
      }
      .btn-view-cv {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
      }
      .btn-upload-again {
        width: 121px;
        height: 32px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        padding: 5px;
      }
    }
  }
}
.slick-prev {
  left: -25px !important;
  z-index: 1;
  width: 50px !important;
  height: 50px !important;
}
.slick-next {
  right: -25px !important;
  z-index: 1;
  width: 50px !important;
  height: 50px !important;
}


.group-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 8px;
  span {
    &:first-child {
      border-right: 1px solid #E9E9E9;
     
    }
  }
}
.how-it-work-box{
  border: 1px solid #E9E9E9;
  border-radius:8px!important ;
  box-shadow: none!important;
  &:hover{
    box-shadow: 0px 4px 15px rgba(0, 35, 61, 0.1) !important;
  }
}