.footer-des-company {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  opacity: 0.9;
}
.design-by {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #ffffff;

  opacity: 0.4;
}
.social-icon li a {
  color: #fff;
  border: none;
  display: inline-block;

  text-align: center;
  font-size: 26px;
  width: 30px;
  line-height: 30px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  overflow: hidden;
  position: relative;
  border-radius: 0 !important;
}
.footer.footer-bar {
  padding: 9px 0;
}
.footer {
  background: #0e0e0e;
  padding: 42px 0 0;
  position: relative;
  color: #ffffff;
  ul {
    li,
    a {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      color: #ffffff !important;
      opacity: 0.9;
    }
  }
  &.footer-bar {
    i {
      font-size: 26px;
    }
  }
}
